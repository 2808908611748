/* Put all CSS stuff here.  */

.center {
  text-align: center;
}

/* Song Viewer */
ion-toggle {
  --background: #73d2f8;
  --handle-background: #1f96f8;

  --background-checked: #73d2f8;
  --handle-background-checked: #1f96f8;
}

#songToggler {
  text-align: right;
  margin-bottom: -40px;
}

#musicView {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* Settings Pages */
#feedbackResponseModal {
  padding: 15px;
  background-color: transparent;
}

#feedbackResponseOKButton {
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: 20px;
}

#feedbackFormDiv {
  margin: 10px;
}

#backToSettingsButton {
  margin: 14px;
}

#settingsTitle {
  font-size: large;
  font-weight: bold;
}

ion-modal {
  --border-radius: 8px !important;
}

#lyricTextBox {
  padding-left: 5%;
  white-space: pre-wrap;
}

#lyricViewFooter {
  margin: 20px;
}

.lyricVerse p {
  font-size: 1rem;
  padding-left: 0.5rem;
}

/* something about icons being multiples of 8 pixels in width*/
.pageTurnButton {
  --ionicon-stroke-width: 32px;
  font-size: 72px;
}

/*
*  laptop/desktop sized devices are generally > 1200px.
*    admittedly, this is arbitrary. A full responsive system
*    might be better down the road
*/
@media (min-width: 1200px) {
  .lyricVerse p {
    font-size: 1.1rem;
    padding-left: 1rem;
  }
}

#settingsModal {
  padding: 15px;
  background-color: transparent;
}

#settingsButton {
  width: 18px;
  margin: 10px;
}

#returnToHymnalButton {
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 20px;
}

.hymnalListView {
  cursor: pointer;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
}
